
.login {
  margin: 20px auto;
  width: 300px;
}
.login-screen {
  min-height: 450px;
  background: white; /* For browsers that do not support gradients */
  background: -webkit-linear-gradient(#cfd9df, #e2ebf0); /* For Safari 5.1 to 6.0 */
  background: -o-linear-gradient(#cfd9df, #e2ebf0); /* For Opera 11.1 to 12.0 */
  background: -moz-linear-gradient(#cfd9df, #e2ebf0); /* For Firefox 3.6 to 15 */
  background: linear-gradient(#cfd9df, #e2ebf0); /* Standard syntax */
  padding: 20px;
  border-radius: 5px
}
.app-title {
  text-align: center;
  color: #212121;
}
.login-form {
  text-align: center;
}
.control-group {
  margin-bottom: 10px;
}
.control-group input {
  color: #212121;
  border-bottom: solid 1px #575c63;
}