html, body {
  min-height : 100%;
  background: white;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  border: 0;
  word-break: break-word !important;
  text-wrap: pretty !important;
}

body {
  padding: 0;
  margin: 0;
  font-size : 100%;
  background: white;
  border: none;
}

main {
  overflow-x: hidden;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Noto Sans', sans-serif !important;
  font-weight: 900;
}

/* Smaller font for mobile, overwriting Materialize */
@media only screen and (max-width: 500px) {
  // smallener ratio 0,2619047619
  h1{font-size:2.4rem !important;line-height:105% !important;margin:2.1rem 0 1.68rem 0}
  h2{font-size:2.3rem !important;line-height:110%;margin:1.78rem 0 1.424rem 0}
  h3{font-size:1.8rem !important;line-height:110%;margin:1.46rem 0 1.168rem 0}
  h4{font-size:1.4rem !important;line-height:110%;margin:1.14rem 0 .912rem 0}
  h5{font-size:1.1rem !important;line-height:110%;margin:.82rem 0 .656rem 0}
  h6{font-size:1rem;line-height:110%;margin:.5rem 0 .4rem 0}
}

.fa-1-3 {
  font-size: 1.3em;
}

*, *:before, *:after {box-sizing:  border-box !important;}

*:focus {
  outline: none;
}

a, p, span, em, input, textarea, time, li, ul, ol, div[role=tooltip] {
  font-family: 'Poppins', sans-serif !important;
  font-weight: 400;
}


i {
  font-family: 'Poppins', sans-serif;
}

p strong {
  color: #575c63;
  font-weight: 900;
}

strong {
  color: black;
}

a, a:hover, a:active, a:focus {
  text-decoration: none;
  cursor: pointer;
  color: inherit;
  font-weight: bold;
}

::selection {
  background: #B8D2DD; /* WebKit/Blink Browsers */
}
input::placeholder {
  color: #575c63;
  opacity: 1;
  font-style: italic;
}

ul:not(.browser-default) {
  padding-left: 0;
  list-style-type: none;
}

ul:not(.browser-default) > li {
  list-style-type: none;
}

/* Code. pre */
code {
  color: #575c63;
  background: #f7f7f9;
}
pre {
  background: #f7f7f9 !important;
  color: #575c63;
  border: none;
  min-height: 50px;
  width: 100%;
  padding: 5% 10% 5% 5%;
  border-radius: 5px;
}

img {
  max-width : 100%;
}

svg {
  fill: #555;
}

figure {
  margin: 0; /*fix Post styling error where words are not placed correctly when displaying images */
}

input:not([type]):focus:not([readonly]), input[type=text]:focus:not([readonly]), input[type=password]:focus:not([readonly]), input[type=email]:focus:not([readonly]), input[type=url]:focus:not([readonly]), input[type=time]:focus:not([readonly]), input[type=date]:focus:not([readonly]), input[type=datetime]:focus:not([readonly]), input[type=datetime-local]:focus:not([readonly]), input[type=tel]:focus:not([readonly]), input[type=number]:focus:not([readonly]), input[type=search]:focus:not([readonly]), textarea.materialize-textarea:focus:not([readonly]) {
  border-bottom: 1px solid #B8D2DD;
  box-shadow: 0 1px 0 0 #B8D2DD;
}

 /* transition react css transition group */
.skill-enter {
  opacity: 0.01;
}

.skill-enter.skill-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
  transition-delay: 1s;
}

.skill-leave {
  opacity: 1;
}

.skill-leave.skill-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
  transition-delay: 1s;
}

.text-center {
  text-align: center;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.left {
  float: left !important;
}
.right {
  float: right !important;
}
.center, .center-align {
  text-align: center;
}
.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

/**
* Materialize css cherry picks
* EDITED: Correction factor added when changed title element font from Source Sans Pro to Noto Sans
*/
$font-size-factor: 0.82;

/**
* Correct the font size and margin on `h1` elements within `section` and
* `article` contexts in Chrome, Firefox, and Safari.
*/
h1 {
  font-size: 2em * $font-size-factor;
  margin: 0.67em 0;
}
h1, h2, h3, h4, h5, h6 {
  font-weight: 400;
  line-height: 1.3;
}

h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
  font-weight: inherit;
}

h1 {
  font-size: 4.2rem * $font-size-factor;
  line-height: 110%;
  margin: 2.8rem 0 1.68rem 0;
}

h2 {
  font-size: 3.56rem * $font-size-factor;
  line-height: 110%;
  margin: 2.3733333333rem 0 1.424rem 0;
}

h3 {
  font-size: 2.92rem * $font-size-factor;
  line-height: 110%;
  margin: 1.9466666667rem 0 1.168rem 0;
}

h4 {
  font-size: 2.28rem * $font-size-factor;
  line-height: 110%;
  margin: 1.52rem 0 0.912rem 0;
}

h5 {
  font-size: 1.64rem * $font-size-factor;
  line-height: 110%;
  margin: 1.0933333333rem 0 0.656rem 0;
}

h6 {
  font-size: 1.15rem * $font-size-factor;
  line-height: 110%;
  margin: 0.7666666667rem 0 0.46rem 0;
}

em {
  font-style: italic;
}

strong {
  font-weight: 500;
}
