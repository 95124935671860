/* https://coolors.co/070707-141416-b8d2dd-011936-ffffff */
.c-a-1 {
  color: #B8D2DD !important;
}
.c-a-2 {
  color: #011936 !important;
}

.bg-a-1, .bg-a-1:hover, .bg-a-1:focus, .bg-a-1:active {
  background-color: #B8D2DD !important;
}
.bg-a-2, .bg-a-2:hover, .bg-a-2:focus, .bg-a-2:active {
  background-color: #011936 !important;
}
/* darks */
.c-d-1 {
  color: #212121 !important;
}
.c-d-2 {
  color: #575c63 !important;
}
.c-d-3 {
  color: #313438 !important;
}
.bg-d-1, .bg-d-1:hover, .bg-d-1:focus, .bg-d-1:active {
  background-color: #212121 !important;
}
.bg-d-2, .bg-d-2:hover, .bg-d-2:focus, .bg-d-2:active {
  background-color: #575c63 !important;
}
.bg-d-3, .bg-d-3:hover, .bg-d-3:focus, .bg-d-3:active {
  background-color: #313438 !important;
}
/* lights */
.c-l-1 {
  color: #f0f1f3 !important;
}
.c-l-2 {
  color: #f7f7f9 !important;
}
.bg-l-1, .bg-l-1:hover, .bg-l-1:focus, .bg-l-1:active{
  background-color: #f0f1f3 !important;
}

.bg-l-2, .bg-l-2:hover, .bg-l-2:focus, .bg-l-2:active{
  background-color: #f7f7f9 !important;
}
