.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl,
.navbar > .container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}

@supports (backdrop-filter: blur(20px) saturate(180%)) {
  nav, .nav {
    height: 52px;
  }
  .blurred-nav {
    backdrop-filter: blur(20px) saturate(180%);
    background-color: rgba(255, 255, 255, 0.7);
    height: 52px;
  }
}
@supports not (backdrop-filter: blur(20px) saturate(180%)) {
  nav, .nav {
    height: 52px;
  }
  .blurred-nav {
    /* fallback */
    background-color: rgba(255,255,255,0.7) !important;
    height: 52px;
 }
}

nav, nav .nav-wrapper i, nav a.button-collapse, nav a.button-collapse i {
  line-height: 52px;
}

nav {
  box-shadow: none;
  background: transparent;
  top: 0;
  z-index: 99;
  position: fixed;
}

.navbar-fixed-top {
  width: 100vw;
  max-width: 100vw;
  min-width: 100vw;
  overflow: hidden;
  border-bottom: 1px solid rgba(0, 0, 0, .0975);
}

nav ul a:hover {
  background-color: rgba(0, 0, 0, 0);
}

nav ul {
  display: flex;
}

nav ul > :nth-child(1) {
  margin-left: 1em;
}

nav ul > :nth-child(2) {
  margin-right: auto;
  margin-left: 1em;
}

nav ul > :nth-child(3) {
  margin-right: 1em;
}

nav ul li {
  display: flex;
}

/**
  on mobile
 */
@media only screen and (max-width: 414px) {
  nav ul p {
    font-size: 0.9em;
  }
  .navbar-fixed-top {
    position: fixed;
    top: 0;
  }
}

.right-container {
  padding-right: 1.5em;
}