
/* Helpers */
.row.fix {
  display: flex;
  flex-wrap: wrap;
}

.visually-hidden {
  max-height: 0;
  display: none;
}

.col-centered{
  float: none !important;
  margin: 0 auto !important;
}

.fa-spacer {
  margin: auto 1%;
}

/* Image zoom */
.img-zoom {
  z-index: 999;
  transition-delay: 0s;
  transition: all 0.3s ease;
}

.img-zoom:hover {
  cursor: zoom-in;
}

.img-zoom:hover {
  z-index: 9999;
  cursor: zoom-out;
  transform: scale(1.5) !important;
}

.clickable {
  cursor: pointer !important;
}