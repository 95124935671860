
.pulse-animation {
  animation-name: pulse;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-fill-mode: backwards;
  animation-timing-function: cubic-bezier(0.1, 0.3, 0.3, 1.0);
}

.tap-target-wrapper.open .tap-target-wave::after {
  visibility: visible;
  -webkit-animation: pulse-animation 1s cubic-bezier(0.24, 0, 0.38, 1) infinite;
          animation: pulse-animation 1s cubic-bezier(0.24, 0, 0.38, 1) infinite;
  -webkit-transition: opacity .3s,
 visibility 0s 1s,
 -webkit-transform .3s;
  transition: opacity .3s,
 visibility 0s 1s,
 -webkit-transform .3s;
  transition: opacity .3s,
 transform .3s,
 visibility 0s 1s;
  transition: opacity .3s,
 transform .3s,
 visibility 0s 1s,
 -webkit-transform .3s;
}

.pulse {
  overflow: visible;
  position: relative;
}

.pulse::before {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: inherit;
  border-radius: inherit;
  -webkit-transition: opacity .3s, -webkit-transform .3s;
  transition: opacity .3s, -webkit-transform .3s;
  transition: opacity .3s, transform .3s;
  transition: opacity .3s, transform .3s, -webkit-transform .3s;
  -webkit-animation: pulse-animation 1s cubic-bezier(0.24, 0, 0.38, 1) infinite;
          animation: pulse-animation 1s cubic-bezier(0.24, 0, 0.38, 1) infinite;
  z-index: -1;
}

@-webkit-keyframes pulse-animation {
  0% {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    opacity: 0;
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
  }
}

@keyframes pulse-animation {
  0% {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    opacity: 0;
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
  }
}
