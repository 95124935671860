.header-container {
    padding: 0 3% 0 3%;
    background: white;
    color: black;
}

.header-container-bg {
    min-height: calc(100vw / 2.75);
    box-shadow: 20px -5em 1em 10px white inset;
    text-shadow: 0   1px 8px white;
    padding: 0 1em 0 1em;
    position: relative;
    color: black;
    background-size: cover !important;
}

.header-main-header {
    font-weight: bold;
    line-height: 1.2;
    position: relative;
    display: inline-block;
    /*margin: .25em;
    padding: .5em .75em;*/
    text-align: center;
    /* Color fallback */
    /*color: #f0f1f3;*/
    color: #212121;
}

.header-container h3 {
    margin-top: 0 !important;
}

.header-top {
    margin: 84px auto;
}

.header-top p {
    margin: 24px auto;
    color: #212121;
}

.header-top h1 {
    word-spacing: 1000em;
    word-break: keep-all;
    text-align: justify;
    line-height: 1.2;
}

.header-left {
    color: #212121;
}

.header-right {
    /*color: #f0f1f3;*/
    color: #212121;
    word-break: keep-all;
    text-align: justify;
    padding: 0;
}

/* Clip text element */
.clip-text {
    font-weight: bold;
    line-height: 1.2;
    position: relative;
    display: inline-block;
    /*margin: .25em;
    padding: .5em .75em;*/
    text-align: center;
    /* Color fallback */
    color: white;
    background-color: white;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.clip-text:before,
.clip-text:after {
    position: absolute;
    content: '';
}

/* Background */
.clip-text:before {
    z-index: -2;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: inherit;
}

/* Text Background (black zone) */
.clip-text:after {
    position: absolute;
    z-index: -1;
    top: .125em;
    right: .125em;
    bottom: .125em;
    left: .125em;
    background-color: #000;
}

/* Change the background position to display letter when the black zone isn't here */
.clip-text--no-textzone:before {
    background-position: -.65em 0;
}

.clip-text--no-textzone:after {
    content: none;
}

/* Use Background-size cover for photo background and no-repeat background */
.clip-text--cover,
.clip-text--cover:before {
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    background-size: cover;
    background-position: 50% 50%;
}
