/* Link line styles */

@media (hover: hover) {
  span .line-animation:hover {
    cursor: pointer;
  }

  .line-animation:hover, .line-animation:active, .line-animation:focus{ text-decoration: none !important; }
  .line-animation {
    position: relative;
    display: inline-block;
    transition: color .2s ease;

    &:hover {

      &::after,
      &::before {
        width: 100%;
        left: 0;
      }

    }
  }

  .line-animation {
    &::after,
    &::before {
      content: '';
      position: absolute;
      top: calc(100% - 20%);
      width: 0;
      right: 0;
      height: 3px;
    }

    &::before {
      transition: width .25s cubic-bezier(0.51, 0.18, 0, 0.88) .1s;
      background: #212121;
    }

    &::after {
      transition: width .2s cubic-bezier(0.29, 0.18, 0.26, 0.83);
      background: #B8D2DD;
    }

  }
}
