.spinner-container {
    background: white;
    background: url('/public/images/loading-background.jpg') center center no-repeat;
    background-size: cover;
}

.offline-text {
    margin: 4em 0 0 0;
    padding: 2em;
}

.spinner {
    height: calc(100vh - 52px);
    margin: auto calc(50% - (64px / 2));
}

.sprinner-svg {
    top: calc(50vh - (64px / 2));
    position: fixed;
}