footer {
    margin-top: 150px;
}

.footer-container {
    padding: 1.5em;
}

.sec-1-text {
    margin-top: 10%;
    color: #575c63;
}

.sec-1-copyright {
    margin-top: 5em;
    margin-bottom: 2em;
    color: #575c63;
}

.sec-2-link {
    margin-top: 0.25em;
    color: #575c63;
    font-weight: inherit;
    cursor: pointer;
}

.sec-3-link {
    margin-top: 0.25em;
    color: #575c63;
    font-weight: inherit;
    cursor: pointer;
}

.sec-4-link {
    margin-top: 0.25em;
    color: #575c63;
    font-weight: inherit;
    cursor: pointer;
}