
/* Notifications */
.toaster {
  ::-webkit-scrollbar {
    display: none;
  }
  ::-webkit-scrollbar {
      width: 0px;  /* remove scrollbar space */
      background: transparent;  /* optional: just make scrollbar invisible */
  }
  /* optional: show position indicator in red */
  ::-webkit-scrollbar-thumb {
      background: #B8D2DD;
  }
  font-size: 1.1em;
  position: fixed;
  z-index: 99;
  top: 55px;
  right: 10px;
  opacity: 1;
  -webkit-transform: translateX(0) rotateY(0deg);
  transform: translateX(0) rotateY(0deg);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.toaster--toggle {
  -webkit-transform: translateX(900px) rotateY(40deg);
  transform: translateX(900px) rotateY(40deg);
  opacity: 0;
}

.notify-count {
  width: 22px;
  height: 22px;
  font-size: 13px;
  font-weight: 500;
  color: white;
  line-height: 26px;
  padding: 0 8px;
  border-radius: 8px;
}

.notification-icon {
  font-size: 1.2em;
  padding-left: 0.5em;
}

@media (hover: hover) {
  .notification-icon:hover {
    cursor: pointer;
    transition: all 300ms ease;
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
    transform: scale(1.2);
  }
}

@media (max-width: 380px) {
  .toast {
    min-width: 200px;
  }
}
@media (min-width: 381px) {
  .toast {
    min-width: 300px;
  }
}

.toast {
  float: right !important;
  max-width: 60%;
  word-break: keep-all;
  color: #212121;
  padding: 0.75em;
  margin-bottom: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 4px #ddd;
  opacity: 0;
  -webkit-transform: translateY(-10px) rotateY(40deg);
  transform: translateY(-10px) rotateY(40deg);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.toast-content {
  margin: auto 0;
  max-width: 85%;
}

.toast-dismiss-icon {
  cursor: pointer;
  font-style: normal;
  overflow: hidden;
  white-space: nowrap;
  top: 45%;
  right: 5px;
  position: absolute;
}

.toast-cancel-icon {
  font-style: normal;
  overflow: hidden;
  white-space: nowrap;
  /*top: 45%;*/
  width: 50px;
  right: 0px;
  position: absolute;
}

.toast-dismiss-icon, .toast-cancel-icon {
  margin-left: 3px;
}

@media (hover: hover) {
  .toast-dismiss-icon:hover, .toast-cancel-icon:hover {
    transition: all 300ms ease;
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
    transform: scale(1.2);
  }
}

.toast.toast--show {
  -webkit-transform: translateY(0) rotateY(0deg);
  transform: translateY(0) rotateY(0deg);
  opacity: 1;
}

.toast--success {
  background-color: #B8D2DD;
}

.toast--error {
  background-color: #ff4148;
}

.toast--notify {
  background-color: #B8D2DD;
}
