/* Settings */
/* disabled, add index.html -> <div id="settings" class="setter"></div> */
.setter {
  /* Scrollable space */
  overflow-y: scroll;
  height: 100vh;
  width: 20%;
  min-width: 200px;
  background: #212121;
  color: white;

  font-size: 1.2em;
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  opacity: 1;
  -webkit-transform: translateX(900px) rotateY(40deg);
  transform: translateX(900px) rotateY(40deg);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.setter--toggle {
  -webkit-transform: translateX(0) rotateY(0deg);
  transform: translateX(0) rotateY(0deg);
  opacity: 1;
}
