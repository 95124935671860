.undermaintenance-container {
    background: white;
    min-height: 100vh;
    padding: 2em;
    margin-top: 2em;
}
.contact-link {
    margin-bottom: 0.3em;
}

.contact-link a {
    font-weight: normal;
}

.later-on {
    margin-top: 2em;
}
