.error-container {
    background: white;
    min-height: 100vh;
    max-height: 100vh;
    color: #575c63;
}

.info-text {
    margin-top: 150px;
}

.info-text-2 {
    margin-top: 30px;
}

.error-header {
    font-weight: bold;
    line-height: 1.3;
    position: relative;
    display: inline-block;
    text-align: center;
    color: #212121;
}
