/* Chip and Btn styles */

.btn, .btn-chip, .btn-flat, .btn-square {
  text-transform: none !important;
}

.chip-square, .btn-square {
  -webkit-border-radius:5px !important;
  -moz-border-radius:5px !important;
  border-radius:5px !important;
}

.chip, .btn-chip, .btn-flat {
  box-shadow:
  0 2.8px 2.2px rgba(0, 0, 0, 0.034),
  0 6.7px 5.3px rgba(0, 0, 0, 0.048),
  0 12.5px 10px rgba(0, 0, 0, 0.06),
  0 22.3px 17.9px rgba(0, 0, 0, 0.072),
  0 41.8px 33.4px rgba(0, 0, 0, 0.086),
  0 100px 80px rgba(0, 0, 0, 0.12);
  transition: all .2s ease-in-out;
}

.chip:hover, .btn-chip:hover, .btn-flat:hover {
  box-shadow:
  0 2.8px 2.2px rgba(0, 0, 0, 0.034),
  0 6.7px 5.3px rgba(0, 0, 0, 0.048),
  0 12.5px 10px rgba(0, 0, 0, 0.06),
  0 22.3px 17.9px rgba(0, 0, 0, 0.072),
  0 41.8px 33.4px rgba(0, 0, 0, 0.086),
  0 100px 80px rgba(0, 0, 0, 0.12);
  transform: translateY(-1px);
}

.btn-chip:hover, .btn-flat:hover {
  box-shadow:
  0 2.8px 2.2px rgba(0, 0, 0, 0.034),
  0 6.7px 5.3px rgba(0, 0, 0, 0.048),
  0 12.5px 10px rgba(0, 0, 0, 0.06),
  0 22.3px 17.9px rgba(0, 0, 0, 0.072),
  0 41.8px 33.4px rgba(0, 0, 0, 0.086),
  0 100px 80px rgba(0, 0, 0, 0.12);
  cursor: pointer;
}

.btn-chip {
  cursor: pointer;
}

/* disable for certain ones */
.chip-default, .btn-flat-default, .chip-default:hover, .btn-flat-default:hover {
  box-shadow: none;
  transform: none;
}

/*  Shake on disabled button */
.disabled-shake {
  pointer-events: auto !important;
}
.disabled:hover, .disabled:focus, .disabled:active {
  cursor: default;
  pointer-events: auto !important;
}
.disabled:active {
  animation: shake 150ms 2 linear;
  -moz-animation: shake 150ms 2 linear;
  -webkit-animation: shake 150ms 2 linear;
  -o-animation: shake 150ms 2 linear;
}

.share-btn-twitter {
  background-color: #03a8f4!important;
  box-shadow: 0 10px 20px -8px #03a8f4!important;
}
/* https://codepen.io/anon/pen/gXOmdL?limit=all&page=2&q=like+animation */
.like-btn {
  text-align: center;
  background: #ed2553 !important;
  box-shadow: 0 10px 20px -8px rgb(240, 75, 113) !important;
  color: #fff !important;
  text-decoration: none;
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
}
.like-btn-liked {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.65;
}
.like-btn .fa {
  margin-right: 5px;
}
.animate-like {
  animation-name: likeAnimation;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-duration: 0.65s;
}
@keyframes likeAnimation {
  0%   { transform: scale(30); }
  100% { transform: scale(1); }
}

.btn, .btn-large, .btn-small,
.btn-flat {
  border: none;
  border-radius: 2px;
  display: inline-block;
  height: 36px;
  line-height: 36px;
  padding: 0 16px;
  text-transform: uppercase;
  vertical-align: middle;
  -webkit-tap-highlight-color: transparent;
}

.btn.disabled, .disabled.btn-large, .disabled.btn-small,
.btn-floating.disabled,
.btn-large.disabled,
.btn-small.disabled,
.btn-flat.disabled,
.btn:disabled,
.btn-large:disabled,
.btn-small:disabled,
.btn-floating:disabled,
.btn-large:disabled,
.btn-small:disabled,
.btn-flat:disabled,
.btn[disabled],
.btn-large[disabled],
.btn-small[disabled],
.btn-floating[disabled],
.btn-large[disabled],
.btn-small[disabled],
.btn-flat[disabled] {
  pointer-events: none;
  background-color: #DFDFDF !important;
  -webkit-box-shadow: none;
          box-shadow: none;
  color: #9F9F9F !important;
  cursor: default;
}

.btn.disabled:hover, .disabled.btn-large:hover, .disabled.btn-small:hover,
.btn-floating.disabled:hover,
.btn-large.disabled:hover,
.btn-small.disabled:hover,
.btn-flat.disabled:hover,
.btn:disabled:hover,
.btn-large:disabled:hover,
.btn-small:disabled:hover,
.btn-floating:disabled:hover,
.btn-large:disabled:hover,
.btn-small:disabled:hover,
.btn-flat:disabled:hover,
.btn[disabled]:hover,
.btn-large[disabled]:hover,
.btn-small[disabled]:hover,
.btn-floating[disabled]:hover,
.btn-large[disabled]:hover,
.btn-small[disabled]:hover,
.btn-flat[disabled]:hover {
  background-color: #DFDFDF !important;
  color: #9F9F9F !important;
}

.btn, .btn-large, .btn-small,
.btn-floating,
.btn-large,
.btn-small,
.btn-flat {
  font-size: 14px;
  outline: 0;
}

.btn i, .btn-large i, .btn-small i,
.btn-floating i,
.btn-large i,
.btn-small i,
.btn-flat i {
  font-size: 1.3rem;
  line-height: inherit;
}

.btn:focus, .btn-large:focus, .btn-small:focus,
.btn-floating:focus {
  background-color: #1d7d74;
}

.btn, .btn-large, .btn-small {
  text-decoration: none;
  color: #fff;
  background-color: #26a69a;
  text-align: center;
  letter-spacing: .5px;
  -webkit-transition: background-color .2s ease-out;
  transition: background-color .2s ease-out;
  cursor: pointer;
}

.btn:hover, .btn-large:hover, .btn-small:hover {
  background-color: #2bbbad;
}