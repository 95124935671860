.fullscreen{
  z-index: 9999;
  width: 100%;
  height: 100%;
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  transition: all 0.2s;
}
.fullscreen:first-child {
  width: 100% !important;
  height: 100% !important;
}

.fullscreen-img-backdrop {
  height: 100%;
  position: fixed;
  width: 100%;
  margin: 0;
  top: 0;
  left: 0;
  background: white;
  z-index: 9999;
  cursor: zoom-out !important;
}
.fullscreen-img {
  z-index: 999;
  width: 100%;
  height: 100%;
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  transition: all 0.2s;
  cursor: zoom-out;
}
.fullscreen-img:first-child {
  // Small border to img
  transition: all 0.2s;
  background: #212121;
  padding: 0.5%;
  // To center horizontally and vertically
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  // Make sure img fits to screen, try to avoid overlapping navbar
  max-width: 90vw;
  max-height: 85vh;
  width: auto;
  height: auto;
}
.fullscreen-img-caption {
  text-align: center;
  position: absolute;
  bottom: 10px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
}