.chip {
  display: inline-block;
  height: 32px;
  font-size: 13px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.6);
  line-height: 32px;
  padding: 0 12px;
  border-radius: 16px;
  background-color: #e4e4e4;
  margin-bottom: 5px;
  margin-right: 5px;
}

.chip:focus {
  outline: none;
  background-color: #26a69a;
  color: #fff;
}

.chip > img {
  float: left;
  margin: 0 8px 0 -12px;
  height: 32px;
  width: 32px;
  border-radius: 50%;
}

.chip .close {
  cursor: pointer;
  float: right;
  font-size: 16px;
  line-height: 32px;
  padding-left: 8px;
}

.chips {
  border: none;
  border-bottom: 1px solid #9e9e9e;
  -webkit-box-shadow: none;
          box-shadow: none;
  margin: 0 0 8px 0;
  min-height: 45px;
  outline: none;
  -webkit-transition: all .3s;
  transition: all .3s;
}

.chips.focus {
  border-bottom: 1px solid #26a69a;
  -webkit-box-shadow: 0 1px 0 0 #26a69a;
          box-shadow: 0 1px 0 0 #26a69a;
}

.chips:hover {
  cursor: text;
}

.chips .input {
  background: none;
  border: 0;
  color: rgba(0, 0, 0, 0.6);
  display: inline-block;
  font-size: 16px;
  height: 3rem;
  line-height: 32px;
  outline: 0;
  margin: 0;
  padding: 0 !important;
  width: 120px !important;
}

.chips .input:focus {
  border: 0 !important;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}

.chips .autocomplete-content {
  margin-top: 0;
  margin-bottom: 0;
}

.prefix ~ .chips {
  margin-left: 3rem;
  width: 92%;
  width: calc(100% - 3rem);
}

.chips:empty ~ label {
  font-size: 0.8rem;
  -webkit-transform: translateY(-140%);
          transform: translateY(-140%);
}

.green {
  background-color: #4CAF50 !important;
}

.green.lighten-5 {
  background-color: #E8F5E9 !important;
}

.green.lighten-4 {
  background-color: #C8E6C9 !important;
}

.green.lighten-3 {
  background-color: #A5D6A7 !important;
}

.green.lighten-2 {
  background-color: #81C784 !important;
}

.green.lighten-1 {
  background-color: #66BB6A !important;
}

.white-text {
  color: #FFFFFF !important;
}

.red {
  background-color: #F44336 !important;
}