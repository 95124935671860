/* Text add Drop Caps */
.drop-caps p:nth-child(2)::first-letter, .drop-caps p:nth-child(5n+3)::first-letter {
  color: #212121;
  float: left;
  font-size: 85px;
  line-height: 55px;
  padding-top: 12px;
  padding-right: 8px;
  padding-left: 3px;
  font-family: 'Noto Sans', sans-serif !important;
  font-weight: 900;
}